<template>
  <div class="app-container">
    <Title :data="data"></Title>
    <div style="display: flex">
      <div style="width: 80%">
        <batchadd
          title="全度教学巡课-学生评价反馈表"
          text="全度教学巡课-学生评价反馈表"
          :dataList="form.student_evaluation_feedback_form"
          :isbtn="false"
          @edit="clickUpload('feedback')"
          @updata='getDetail()'
          style="margin-bottom: 20px"
        ></batchadd>
        <batchadd
          title="全度教学巡课-课堂情况记录表"
          text="全度教学巡课-课堂情况记录表"
          :dataList="form.class_record"
          :isbtn="false"
          @edit="clickUpload('class_record')"
          @updata='getDetail()'
          style="margin-bottom: 20px"
        ></batchadd>
        <batchadd
          title="全度教学巡课-巡课评分总表"
          text="全度教学巡课-巡课评分总表"
          :dataList="form.total_score_of_class_tour"
          :isbtn="false"
          @edit="clickUpload('class_tour')"
          @updata='getDetail()'
          style="margin-bottom: 20px"
        ></batchadd>
      </div>
      <Uptips style="width: 20%"></Uptips>
    </div>
    <div class="bottom_btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="submit" :loading="btnLoading"
        >保存并提交</el-button
      >
    </div>
  </div>
</template>

<script>
// import BatchAdd from "@/components/Batch/index.vue";
import batchadd from "@/components/Batch_add/index.vue";
import request from "@/utils/request";
export default {
  components: {
    batchadd,
  },
  data() {
    return {
      selected: false,
      btnLoading: false,
      formList: [],
      recordList: [],
      tourList: [],
      listQuery: {
        type: 7,
        class_course_id: "",
      },
      data: {},
      form: {},
    };
  },
  created() {
    this.listQuery.class_course_id = this.$route.query.class_course_id;
    this.data = JSON.parse(this.$route.query.data);
    localStorage.setItem("classdata", JSON.stringify(this.data));
    this.form = {
      student_evaluation_feedback_form: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "student_evaluation_feedback_form",
        data: [],
      },
      class_record: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "class_record",
        data: [],
      },
      total_score_of_class_tour: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "total_score_of_class_tour",
        data: [],
      },
    };
    this.getDetail();
  },
  methods: {
    clickUpload(e) {
      switch (e) {
        case "feedback":
          this.form.student_evaluation_feedback_form.is_store = 1;
          break;
        case "class_record":
          this.form.class_record.is_store = 1;
          break;
        case "class_tour":
          this.form.total_score_of_class_tour.is_store = 1;
          break;
      }
    },
    getDetail() {
      request({
        url: "/api/schoolend/eduAdmin/dataDetail",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        if (response.data.student_evaluation_feedback_form)
          this.form.student_evaluation_feedback_form =
            response.data.student_evaluation_feedback_form;
        if (response.data.class_record)
          this.form.class_record = response.data.class_record;
        if (response.data.total_score_of_class_tour)
          this.form.total_score_of_class_tour =
            response.data.total_score_of_class_tour;
      });
    },
    submit() {
      if (
        this.form.student_evaluation_feedback_form.is_store != 1 &&
        this.form.class_record.is_store != 1 &&
        this.form.total_score_of_class_tour.is_store != 1
      ) {
        this.$alert("请修改或者上传后提交", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            return;
          },
        });
      }
      let isup = this.form.student_evaluation_feedback_form.data.findIndex(
        (value) => value.name == null
      );
      let isrecord = this.form.class_record.data.findIndex(
        (value) => value.name == null
      );
      let isclass = this.form.total_score_of_class_tour.data.findIndex(
        (value) => value.name == null
      );
      if (isup != -1 || isrecord != -1 || isclass != -1) {
        this.$message({
          type: "warning",
          message: "添加后,请上传文件!!",
        });
        return;
      }
      let submitForm = {};
      if (this.form.student_evaluation_feedback_form.is_store) {
        submitForm.student_evaluation_feedback_form =
          this.form.student_evaluation_feedback_form;
      }
      if (this.form.class_record.is_store) {
        submitForm.class_record = this.form.class_record;
      }
      if (this.form.total_score_of_class_tour.is_store) {
        submitForm.total_score_of_class_tour =
          this.form.total_score_of_class_tour;
      }
      if (Object.keys(submitForm).length == 0) {
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/schoolend/eduAdmin/dataStore",
        method: "post",
        data: submitForm,
      })
        .then((response) => {
          this.btnLoading = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          // this.$router.go(0);
          this.getDetail();
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.content {
  width: 50%;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}
.bottom_btn {
  display: flex;
  justify-content: center;
}
</style>
